.footer {
  width: 100%;
  text-align: center;
  padding: 20px;
  background-color: #f1f1f1; /* Change to desired background color */
  font-size: 14px; /* Adjust the font size as needed */
  position: fixed;
  bottom: 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-media-links {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.social-media-link {
  color: inherit;
  text-decoration: none;
  margin: 0 10px;
  font-size: 24px; /* Adjust the icon size as needed */
}

.social-media-link:hover {
  color: #007BFF; /* Change to desired hover color */
  text-decoration: none;
}

.footer-text {
  margin-top: 10px;
}

.footer-text a {
  color: inherit; /* Use the same color as the surrounding text */
  text-decoration: none; /* Remove underline */
}

.footer-text a:hover {
  text-decoration: none; /* Ensure no underline on hover */
}
